import styled from '@emotion/styled'
import { IconBox, Props as IconBoxProps } from 'app/components/Common/IconBox'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icons?: IconBoxProps[]
}

export const Highlights = memo(function Highlights({ icons }: Props) {
  if (!icons) {
    return null
  }

  return (
    <Container row wrap stretch tag="section">
      {icons.map((item, index) => (
        <IconBox key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};

  > div {
    width: 25%;
    &:nth-of-type(odd) {
      background: ${({ theme }) => theme.colors.variants.primaryLight};
      color: ${({ theme }) => theme.colors.variants.primaryDark};
    }
    @media (min-width: 1200px) {
      &:nth-of-type(n + 5):nth-of-type(odd) {
        background: ${({ theme }) => theme.colors.variants.primaryDark};
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
      }
      &:nth-of-type(n + 5) {
        &:nth-of-type(even) {
          background: ${({ theme }) => theme.colors.variants.primaryLight};
          color: ${({ theme }) => theme.colors.variants.primaryDark};
        }
      }
    }
  }

  @media (max-width: 1199px) {
    > div {
      width: 100%;

      &:nth-of-type(n + 5) {
        img {
          filter: invert(1);
        }
      }
    }
  }
`
