import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const Service = memo(function Service({
  cta,
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container className={!image ? 'empty' : undefined} dial={5}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title
              className="text-mark"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        {cta ? (
          <FadeInUp>
            <CTA {...cta} variant="invert" />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 50%;
  background-color: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 17.3125rem 10.556vw;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &.empty {
    /* background-image: url('illustration-1.svg');
    background-position: center;
    background-repeat: no-repeat; */
    &:before {
      display: none;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 5.625rem 1.875rem;
    &.empty {
      background-size: 80%;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Label = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 0.875rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  max-width: 14.125rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin: auto;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
  margin-top: 1.875rem;
`

const CTA = styled(Button)`
  margin-top: 3rem;
`
