import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Facebook, Instagram } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  facebookURL?: string
  images: ImageProps[]
  instagramURL?: string
  title?: string
}

export const SocialWall = memo(function SocialWall({
  facebookURL,
  images,
  instagramURL,
  title,
}: Props) {
  if (images?.length < 1) {
    return null
  }

  return (
    <Container row stretch tag="section" wrap>
      <LeftSide>
        <FadeInUp>
          <Hashtag>#theromehello</Hashtag>
        </FadeInUp>

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {facebookURL || instagramURL ? (
          <FadeInUp>
            <Social row>
              {facebookURL ? (
                <Anchor
                  aria-label="Facebook"
                  href={facebookURL}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Facebook />
                </Anchor>
              ) : null}

              {instagramURL ? (
                <Anchor
                  aria-label="Instagram"
                  href={instagramURL}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Instagram />
                </Anchor>
              ) : null}
            </Social>
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide row wrap>
        {uniq(images)
          .slice(0, 6)
          .map((item, index) => (
            <Item key={index}>
              <LazyLoadComponent>
                <Image {...item} />
              </LazyLoadComponent>
            </Item>
          ))}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 25%;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  padding: 3.75rem;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 3.75rem 1.875rem;
  }
`

const Hashtag = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 0.875rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`

const Social = styled(FlexBox)`
  margin-top: 3rem;

  @media (max-width: 1199px) {
    margin-top: 2.25rem;
  }
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  border: 0.0625rem solid transparent;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  &:nth-of-type(2) {
    margin-left: 0.625rem;
  }
  &:hover {
    background: transparent;
    border-color: ${({ theme }) => theme.colors.variants.primaryDark};

    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark};
    }
  }

  svg {
    width: auto;
    height: 1rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    transition: 0.3s ease-in-out;
  }
`

const RightSide = styled(FlexBox)`
  width: 75%;

  @media (max-width: 1199px) {
    width: 100%;
  }
`

const Item = styled.div`
  width: 33.333%;
  aspect-ratio: 1;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 50%;
  }
`
